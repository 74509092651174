<template>
  <y_layout>
    <desc_section :desc="desc"></desc_section>
<!--    <order_echart style="margin-bottom: 1rem"></order_echart>-->
<!--    <member_echart></member_echart>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import desc_section from "@/view/home/index/desc_section";
// import order_echart from "@/view/home/index/order_echart";
// import member_echart from "@/view/home/index/member_echart";
export default {
  components: {
    y_layout,
    desc_section,
    // order_echart,
    // member_echart,
  },
  name: "index",
  data() {
    return {
      tips: "",
      desc: {
        wait_send: 0,
        wait_service:0,
        goods_stock_warning:0,
        wait_cash:0,
        yesterday_paid_order:0,
        yesterday_paid_order_statistics:0,
        yesterday_money:0,
        yesterday_money_statistics:0,
        today_added_member:0,
        today_added_member_statistics:0,
        this_month_added_member:0,
        this_month_added_member_statistics:0,
      }
    }
  },
  mounted() {
    this.$api.shop.desc().then(res=>{
      this.desc = res;
    })
  }
}
</script>
<style>
.el-card__header {
  padding: 0 1rem;
  line-height: 2.6rem;
  font-size: .9rem;
}
</style>
<style scoped>

</style>